<template>
    <div class="My">
        <keep-alive>
            <router-view v-if="!$route.meta.noKeepAlive"></router-view>
        </keep-alive>
        <router-view v-if="$route.meta.noKeepAlive"></router-view>
    </div>
</template>
<script>
export default {
    name: 'My',
    data() {
        return {

        }
    },
    created() {
        this.toPage()
    },
    methods: {
        toPage() {
            if (this.$route.path == '/my' || this.$route.path == '/my/') {
                this.$router.replace('/my/center')
            }
        }
    },
    watch: {
        $route() {
            this.toPage()
        }
    }
}
</script>
